$jet: #292929
$dim-gray: #666666
$grey: #B8B8B8
$wine: #683131
$brandy: #8B4141
$platinum: #E2E2E2
$cg-blue: #355691
$cyan-process: #4c76bd

$default-font: 'Sitka Text', serif
$heading-font: 'Sitka Heading', serif
$subheading-font: 'Sitka Subheading', serif
$link-font: 'Sitka Display', serif

#Component
  width: 100vw
  position: absolute

  #Content
    padding-top: 1rem

  #Head
    height: 10vh
    background-color: $jet
    display: flex
    align-items: center
    justify-content: center
    border-radius: 2rem
    margin-bottom: 2rem

    #BackButton
      position: absolute
      left: 0
      display: flex
      align-items: center
      justify-content: center
      background-color: $wine
      height: 100%
      width: 8rem
      border-bottom-left-radius: 2rem
      border-top-left-radius: 2rem
      color: $grey
      text-decoration: none !important
      padding-top: .15rem
      padding-left: 0.25rem
      font-size: 17pt

      &:hover
        background-color: $brandy
        color: $platinum

    #Header
      color: $grey
      padding-bottom: 0
      margin-bottom: 0
      font-size: 20pt
      padding-top: 0.2rem

  #DoneButton
    font-family: $link-font
    font-weight: bold
    text-align: center
    color: $grey
    background-color: $wine
    width: 16rem
    border-radius: 10rem
    margin: 0 1rem 1.5rem 1rem
    text-decoration: none
    outline: none
    border: 0
    padding-top: 0.15rem
    font-size: 16pt

    &:hover
      background-color: $brandy
      color: $platinum

#Options
  height: 40vh
  margin-bottom: .8rem

#Option
  text-align: center
  display: inline-flex
  justify-content: center
  align-items: center
  width: 100%
  background-color: $jet
  margin-bottom: 3rem
  text-decoration: none
  font-size: 20pt
  border-radius: 3rem
  outline: none
  border: 0
  color: $grey

  &:hover, &.Selected
    color: $platinum
    background-image: url("./img/blazin.svg")
    background-size: contain
    background-position: center
    background-repeat: no-repeat

// WIDTH-related responsiveness
@media only screen and (max-width: 1199px)
  #Component
    padding-top: 0
    margin-top: 2.5rem

@media only screen and (max-width: 992px)
  #Head
    height: 4rem
    #BackButton
      width: 7rem
      font-size: 17pt !important
    #Header
      font-size: 17pt !important
  #DoneButton
    padding-top: 0.2rem
    margin-top: 2rem
    font-size: 18pt !important
    width: 12rem

@media only screen and (max-width: 576px)
  #Head
    justify-content: flex-end
    padding-right: 2rem
    height: 3rem
    #BackButton
      width: 5rem
      font-size: 13pt
    #Header
      font-size: 13pt
  #DoneButton
    margin-top: 2rem
    font-size: 14pt !important
    width: 10rem

// HEIGHT-related responsiveness
@media only screen and (min-height: 600px)
  #Content
    padding-top: 3rem
  #Head
    height: 8vh
    #BackButton
      padding-top: 0.1rem
      font-size: 17pt
    #Header
      padding-top: 0.2rem
      font-size: 25pt
  #Options
    height: 51vh
  #DoneButton
    padding-top: 0.15rem
    font-size: 17pt

@media only screen and (min-height: 800px)
  #Content
    padding-top: 5rem
  #Head
    #BackButton
      font-size: 20pt
    #Header
      font-size: 30pt
  #Options
    height: 52vh
  #DoneButton
    font-size: 20pt

@media only screen and (min-height: 950px)
  #Head
    height: 5rem
  #Options
    height: 56.6vh
  #DoneButton
   font-size: 24pt

@media only screen and (min-height: 1080px)
  #Options
    height: 61vh

@media only screen and (min-height: 1200px)
  #Options
    height: 64vh

@media only screen and (min-height: 1400px)
  #Options
    height: 70vh

@media only screen and (min-height: 1600px)
  #Options
    height: 73vh

@media only screen and (min-height: 1800px)
  #Options
    height: 76vh

@media only screen and (min-height: 2000px)
  #Options
    height: 76.5vh
  #DoneButton
    font-size: 30pt

