@use '../../../vars'

#ThankYou
  width: 100vw
  position: absolute

  #PageRow
    display: block
    padding-top: 9rem

  .PageHead
    padding-top: 1rem
    padding-bottom: 0.5rem
    background-color: vars.$jet
    text-align: center
    border-radius: 1rem
    margin-bottom: 1rem
    background-image: url("../../../img/blazin.svg")
    background-position: center
    background-repeat: repeat

    .Header
      color: vars.$grey
      font-size: 35pt
      padding-top: 0.4rem

    h4
      color: vars.$grey
      font-size: 20pt

  div.Pizza
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding-top: 1rem
    width: 100%
    background-color: vars.$jet
    margin-bottom: 1rem
    text-decoration: none
    border-radius: 1rem
    color: vars.$grey

    p
      font-size: 15pt

  div.Sides, div.Drinks
    padding: 1rem 0 0.5rem 0
    display: inline-flex
    justify-content: center
    align-items: center
    flex-direction: column
    width: 100%
    background-color: vars.$jet
    margin-bottom: 1rem
    text-decoration: none
    border-radius: 1rem
    color: vars.$grey

  div.Address
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding-top: 1rem
    padding-bottom: 1rem
    background-color: vars.$jet
    margin-bottom: 1rem
    text-decoration: none
    border-radius: 1rem
    color: vars.$grey

  article.Done
    display: flex
    align-items: center
    flex-direction: column

  h4.Total
    border-radius: 1rem
    padding: 1.4rem 4rem 1.1rem 4rem
    background-color: vars.$jet
    text-align: center
    font-weight: bold
    font-size: 20pt
    color: vars.$grey
    margin: 0.5rem 0 1rem 0

  a.Confirm
    font-family: vars.$link-font
    font-weight: bold
    text-align: center
    font-size: 23pt
    color: vars.$grey
    background-color: vars.$wine
    width: 16rem
    border-radius: 10rem
    margin: 0 1rem 0.5rem 1rem
    text-decoration: none !important
    padding-top: 0.3rem

    &:hover
      background-color: vars.$brandy
      color: vars.$platinum



@media only screen and (max-width: 1200px)
  #ThankYou #PageRow
    padding-top: 7rem

@media only screen and (max-width: 992px)
  #ThankYou
    a.Confirm
      font-size: 17pt
      padding: 0.7rem 0 0.5rem 0
      margin-bottom: 1rem

    h4.Total
      margin-bottom: 1.3rem

@media only screen and (max-width: 576px)
  #ThankYou
    .PageHead .Header
      font-size: 20pt

    div.Pizza p, div.Sides h5, div.Drinks h5, div.Address h5
      font-size: 13pt
      text-align: center

    div.Pizza h3, div.Sides h3, div.Drinks h3, div.Address h3, h4.Total
      font-size: 18pt
