#MaxToppingsModal
  .modal-body, .modal-header
    text-align: center
    display: flex
    justify-content: center


  .modal-footer
    text-align: center
    display: flex
    justify-content: center
    padding-bottom: 0

  h4
    font-weight: bold

  p
    font-weight: bold
    font-size: 15pt
    margin-bottom: 0


  button
    cursor: pointer
    height: 3rem !important
    text-align: center !important
    font-size: 17pt !important
    color: #E2E2E2 !important
    background-color: #683131
    width: 16rem !important
    border-radius: 10rem !important
    margin: 0 1rem 1.5rem 1rem !important
    text-decoration: none !important
    outline: none
    border: 0

