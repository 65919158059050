@use '../../vars'

nav
  background-color: vars.$jet
  z-index: 2
  height: 5.5rem

  #Banner
    margin-bottom: 0.4rem

  .BannerText
    color: vars.$grey
    font-family: 'Sitka Banner', serif !important
    font-size: 30pt
    margin-bottom: -1rem
    &:hover
      color: vars.$platinum

  .nav-link
    color: vars.$grey
    font-family: 'Sitka Display', serif !important
    font-size: 20pt
    font-weight: 400
    margin-left: 1.5rem
    padding: 1.3rem 0 0.7rem 0

    &:hover
      color: vars.$platinum !important

    &.active
      background-image: url("../../img/blazin.svg")
      background-size: contain
      background-repeat: no-repeat
      background-position: center
      color: vars.$platinum !important


  #NavbarToggler
    font-size: 35pt
    color: vars.$grey !important

    &:hover
      color: vars.$platinum !important

  .navbar-toggler
    border: none

  #NavbarCollapse
    background-color: vars.$jet
    box-shadow: 0.2rem 0.2rem 0.2rem
