@use '../../../../vars'

#PizzaTopping
  @extend #Component

  #ExceptBasket
    @extend #Content

  .PageHead
    @extend #Head

    .BackButton
      @extend #BackButton

    .Header
      @extend #Header

  button.Done
    @extend #DoneButton

  #Toppings
    @extend #Options
    button, a
      @extend #Option
      height: 80%
      font-size: 15pt
      margin-bottom: .2rem

@media only screen and (max-width: 992px)
  #PizzaTopping #Toppings button
    font-size: 12pt !important
    margin-bottom: 0.5rem

@media only screen and (min-height: 600px)
  #PizzaTopping #Toppings button
    height: 70%
    font-size: 15pt
    margin-bottom: 1rem

@media only screen and (min-height: 800px)
  #PizzaTopping #Toppings button
    height: 80%
    font-size: 17pt

@media only screen and (min-height: 1000px)
  #PizzaTopping #Toppings button
    font-size: 20pt

@media only screen and (min-height: 1600px)
  #PizzaTopping #Toppings button
    font-size: 25pt