@use '../../../../vars'

#SelectCategory
  @extend #Component

  #ExceptBasket
    @extend #Content

  .Options
    @extend #Options
    margin-top: .2rem
    a
      @extend #Option
      font-size: 30pt
      padding-top: 0.4rem
      height: 19vh
      margin-bottom: 1rem

@media only screen and (max-width: 1199px)
  #SelectCategory .Options
    margin-bottom: 10rem
    font-size: 40pt

@media only screen and (min-height: 600px)
  #SelectCategory .Options
    margin-top: 1.1rem
    a
      font-size: 40pt !important
      padding-top: 0.4rem
      height: 17vh
      &.Pizzas, &.Sides
        margin-bottom: 2rem


@media only screen and (min-height: 800px)
  #SelectCategory .Options a
    height: 18.4vh

@media only screen and (min-height: 950px)
  #SelectCategory .Options a
    height: 21.4vh

@media only screen and (min-height: 1080px)
  #SelectCategory .Options a
    height: 22.4vh

@media only screen and (min-height: 1200px)
  #SelectCategory .Options
    margin-top: 1.8rem
    a
      height: 23.4vh

@media only screen and (min-height: 1400px)
  #SelectCategory .Options
    margin-top: 3.2rem

@media only screen and (min-height: 1600px)
  #SelectCategory .Options
    margin-top: 5.2rem

@media only screen and (min-height: 1800px)
  #SelectCategory .Options
    margin-top: 5.8rem

@media only screen and (min-height: 2000px)
  #SelectCategory .Options
    margin-top: 7rem !important
    a
      font-size: 50pt



