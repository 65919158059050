@use '../../../vars'

#Register
  background-color: vars.$platinum
  width: 100vw
  position: absolute
  padding-top: 10rem

  .PageHead
    height: 5rem
    background-color: vars.$jet
    display: flex
    align-items: center
    justify-content: center
    border-radius: 1rem
    margin-bottom: 2rem

    .BackButton
      position: absolute
      left: 0
      display: flex
      align-items: center
      justify-content: center
      background-color: vars.$wine
      height: 100%
      width: 8rem
      font-size: 22pt
      border-bottom-left-radius: 1rem
      border-top-left-radius: 1rem
      color: vars.$grey
      text-decoration: none !important
      padding-top: 0.4rem

      &:hover
        background-color: vars.$brandy
        color: vars.$platinum

    .Header
      color: vars.$grey
      font-size: 35pt
      margin-bottom: 0
      padding-bottom: 0
      padding-top: 0.5rem

  .Form
    margin-top: 0
    background-color: vars.$jet
    border-radius: 1rem
    padding-top: 2.2rem

    input
      text-align: center
      background-color: vars.$wine
      font-family: vars.$default-font
      color: vars.$grey
      font-size: 16pt

    label
      color: vars.$grey
      font-family: vars.$subheading-font
      font-size: 17pt

  .form-group
    text-align: center
    margin-bottom: 2rem

  .Done
    margin-top: 1rem
    display: flex
    align-items: center
    flex-direction: column

  .Confirm
    font-family: vars.$link-font
    font-weight: bold
    text-align: center
    font-size: 23pt
    color: vars.$grey
    background-color: vars.$wine
    width: 16rem
    border-radius: 10rem
    margin: 0.5rem 1rem 0 1rem
    text-decoration: none !important
    padding-top: 0.7rem 

    &.active:hover
      color: vars.$platinum
      background-color: vars.$brandy

  .SignIn
    text-align: center

    a
      font-family: vars.$link-font
      font-size: 16pt
      color: vars.$jet
      text-decoration: underline
      font-weight: bold

      &:hover
        color: vars.$dim-gray

@media only screen and (max-width: 1200px)
  #Register
    padding-top: 7rem

@media only screen and (max-width: 992px)
  #Register .PageHead
    height: 4rem

@media only screen and (max-width: 576px)
  #Register
    .PageHead
      justify-content: flex-end
      padding-right: 2rem

      .BackButton
        width: 5rem
        font-size: 15pt

      .Header
        font-size: 20pt

    .Confirm
      font-size: 18pt
      width: 16rem
      text-decoration: none !important
