@use '../../../vars'

#ReviewOrder
  width: 100vw
  position: absolute

  #PageRow
    display: block
    padding-top: 9rem

  .PageHead
    height: 5rem
    background-color: vars.$jet
    display: flex
    align-items: center
    justify-content: center
    border-radius: 1rem
    margin-bottom: 2rem

    .BackButton
      position: absolute
      left: 0
      display: flex
      align-items: center
      justify-content: center
      background-color: vars.$wine
      height: 100%
      width: 8rem
      font-size: 22pt
      border-bottom-left-radius: 1rem
      border-top-left-radius: 1rem
      color: vars.$grey
      text-decoration: none !important
      padding-top: 0.4rem

      &:hover
        background-color: vars.$brandy
        color: vars.$platinum
        cursor: pointer

    .Header
      color: vars.$grey
      font-size: 35pt
      margin-bottom: 0
      padding-bottom: 0
      padding-top: 0.5rem

  div.Sides, div.Drinks
    padding: 1rem 0 0.5rem 0
    display: inline-flex
    justify-content: center
    align-items: center
    flex-direction: column
    width: 100%
    background-color: vars.$jet
    margin-bottom: 1rem
    text-decoration: none
    border-radius: 1rem
    color: vars.$grey

    button.RemoveSide, button.RemoveDrink
      background: none
      border: none
      padding: 0
      cursor: pointer
      outline: inherit
      margin-left: .3rem

    i.RemoveSide, i.RemoveDrink
      font-size: 12pt
      margin-left: 0.2rem
      color: vars.$grey

      &:hover
        color: vars.$platinum

  div.Pizza
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding-top: 1rem
    width: 100%
    background-color: vars.$jet
    margin-bottom: 1rem
    text-decoration: none
    border-radius: 1rem
    color: vars.$grey

    p
      font-size: 15pt

    i.RemovePizza
      position: absolute
      font-size: 25pt
      color: vars.$grey
      right: 1rem
      top: 1rem
      
      &:hover
        color: vars.$platinum

    button.RemovePizza
      background: none
      border: none
      padding: 0
      cursor: pointer
      outline: inherit
      color: inherit
      font: inherit

  article.Done
    display: flex
    align-items: center
    flex-direction: column

  h4.Total, h4.Total, h4.Date
    border-radius: 1rem
    padding: 1rem 4rem 0.7rem 4rem
    background-color: vars.$jet
    text-align: center
    font-weight: bold
    font-size: 20pt
    color: vars.$grey
    margin: 0.5rem 0 1rem 0

  a.Confirm
    font-family: vars.$link-font
    font-weight: bold
    text-align: center
    font-size: 23pt
    color: vars.$grey
    background-color: vars.$wine
    width: 16rem
    border-radius: 10rem
    margin: 0 1rem 0.5rem 1rem
    text-decoration: none !important
    padding-top: 0.3rem

    &:hover
      background-color: vars.$brandy
      color: vars.$platinum
      cursor: pointer

@media only screen and (max-width: 1200px)
  #ReviewOrder #PageRow
    padding-top: 7rem

@media only screen and (max-width: 992px)
  #ReviewOrder
    .PageHead
      height: 4rem

    a.Confirm
      font-size: 16pt
      width: 13rem
      margin-bottom: 2rem

    div.Pizza i.RemovePizza
      font-size: 20pt

    h3
      font-size: 17pt

    h5
      font-size: 13pt

    p
      text-align: center

      span
        @extend h5

    h4.Total
      padding: 0.5rem 4rem 0.6rem 4rem
      font-size: 17pt
      margin: 0.5rem 0 1rem 0

@media only screen and (max-width: 576px)
  #ReviewOrder
    .PageHead
      justify-content: flex-end
      padding-right: 2rem

      .BackButton
          width: 5rem
          font-size: 15pt

      .Header
        font-size: 20pt

    a.Done
      margin-top: 1rem
