@use '../../../../vars'

#AddDrink
  @extend #Component

  #ExceptBasket
    @extend #Content

  .PageHead
    @extend #Head

    .Header
      @extend #Header

  #Drinks button
    @extend #Option
    font-size: 15pt
    margin-bottom: 1rem
    height: 10.3vh

  button.Done
    @extend #DoneButton

@media only screen and (min-height: 600px)
  #AddDrink #Drinks button
    margin-bottom: 1rem
    height: 14.3vh

@media only screen and (min-height: 800px)
  #AddDrink #Drinks button
    font-size: 17pt
    height: 15.8vh

@media only screen and (min-height: 950px)
  #AddDrink #Drinks button
    font-size: 20pt
    margin-bottom: 3rem
    height: 13.75vh

@media only screen and (min-height: 1080px)
  #AddDrink #Drinks button
    height: 15.9vh

@media only screen and (min-height: 1200px)
  #AddDrink #Drinks button
    height: 17.35vh

@media only screen and (min-height: 1400px)
  #AddDrink #Drinks button
    height: 19.9vh

@media only screen and (min-height: 1600px)
  #AddDrink #Drinks button
    font-size: 25pt
    height: 21.3vh

@media only screen and (min-height: 1800px)
  #AddDrink #Drinks button
    font-size: 30pt
    height: 22.6vh

@media only screen and (min-height: 2000px)
  #AddDrink #Drinks button
    font-size: 35pt
    height: 23.1vh

