@use '../../vars'

#Home
  z-index: 3
  background-color: vars.$jet
  height: 100vh
  width: 100vw
  position: fixed
  top: 0
  left: 0
  display: flex
  justify-content: center
  align-items: center

  .Banner
    background-image: url("../../img/blazin.svg")
    background-position: left
    background-size: contain
    background-repeat: no-repeat
    padding-left: 6rem
    margin-bottom: 5rem
    justify-content: center
    display: flex
    flex-direction: column

    h1
      color: vars.$grey
      font-size: 110pt
      margin-bottom: -0.5rem

      &.Top
        padding-top: 5rem

      &.Bottom
        line-height: 0.7

  .Buttons
    a
      font-family: 'Sitka Display', serif !important
      font-weight: bold
      text-align: center
      font-size: 27pt
      color: vars.$grey
      background-color: vars.$wine
      width: 16rem
      border-radius: 10rem
      margin: 0 1rem 1.5rem 1rem
      padding-top: 0.5rem
      &:hover
        color: vars.$platinum
        background-color: vars.$brandy

    .Top, .Bottom
      display: flex
      justify-content: center

    .Middle
      margin-bottom: 1.6rem
      display: flex
      justify-content: center

      a
        width: 26rem

    .Bottom
      a
        background-color: vars.$cg-blue
        color: vars.$grey
        width: 3rem
        height: 3rem
        padding: 0
        margin: 0

        &:hover
          color: vars.$platinum
          background-color: vars.$cyan-process

@media only screen and (max-width: 760px)
  #Home
    .Banner
      padding-left: 2.5rem
      padding-bottom: 1rem
      margin-bottom: 4rem
      margin-left: 3rem

      h1
        font-size: 40pt

    .Buttons
      a
        font-size: 15pt
        width: 10rem

      .Middle
        margin-bottom: 0.5rem

      .Bottom
        a
          width: 2.5rem
          height: 2.5rem
          padding-top: 0.3rem

