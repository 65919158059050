#OrderHistory
  background-color: #BCBCBC
  width: 100vw
  position: absolute

  #PageRow
    display: block
    padding-top: 9rem

  .PageHead
    height: 5rem
    background-color: #202020
    display: flex
    align-items: center
    justify-content: center
    border-radius: 1rem
    margin-bottom: 2rem

    .BackButton
      position: absolute
      left: 0
      display: flex
      align-items: center
      justify-content: center
      background-color: #683131
      height: 100%
      width: 8rem
      font-size: 22pt
      border-bottom-left-radius: 1rem
      border-top-left-radius: 1rem
      color: #E2E2E2
      text-decoration: none !important

      &:hover
        background-color: #8B4343
        cursor: pointer

    .Header
      color: #E2E2E2
      font-size: 35pt
      padding-bottom: 0
      margin-bottom: 0

  div.Order
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding-top: 1rem
    width: 100%
    background-color: #202020
    margin-bottom: 1rem
    text-decoration: none
    border-radius: 1rem
    color: #E2E2E2

    p
      font-size: 15pt


  div.Sides, div.Drinks
      padding: 1rem 0 0.5rem 0
      display: inline-flex
      justify-content: center
      align-items: center
      flex-direction: column
      width: 100%
      background-color: #202020
      margin-bottom: 1rem
      text-decoration: none
      border-radius: 1rem
      color: #E2E2E2

  h4.Total, h4.Date
      border-radius: 1rem
      padding: 1rem 4rem 1.1rem 4rem
      background-color: #202020
      text-align: center
      font-weight: bold
      font-size: 20pt
      color: #E2E2E2
      margin: 0.5rem 0 1rem 0

  h4.Message
    border-radius: 1rem
    padding: 1rem 4rem 1.1rem 4rem
    background-color: #202020
    text-align: center
    font-weight: bold
    font-size: 20pt
    color: #E2E2E2
    margin: 0.5rem 0 3rem 0

  article.NoOrder
    display: flex
    align-items: center
    flex-direction: column

  a.OrderNow
    font-family: 'Sitka Display', serif !important
    font-weight: bold
    text-align: center
    font-size: 23pt
    color: #EFEFEF
    background-color: #683131
    width: 16rem
    border-radius: 10rem
    margin: 0 1rem 0.5rem 1rem
    text-decoration: none !important
    padding: 0.5rem 0 0.5rem 0

    &:hover
      background-color: #8B4343



