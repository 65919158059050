@use '../../../vars'

#Basket
  padding-top: 4rem
  background-color: vars.$jet
  min-height: 100vh
  flex-direction: column
  box-shadow: 0.2rem 0.2rem 0.2rem

  h4, h5, h6, p
    padding: 0
    margin-bottom: 0.2rem
    color: vars.$grey
    font-family: vars.$default-font


  .Pizzas p, .Sides, .Drinks
    margin-bottom: 2rem

  .Sides p, #Basket .Drinks p
    margin-bottom: 0

  li
    color: vars.$grey

  a.OrderButton
    font-family: vars.$link-font
    font-weight: bold
    text-align: center
    font-size: 20pt
    color: vars.$grey
    background-color: vars.$wine
    padding-left: 3rem
    padding-right: 3rem
    border-radius: 10rem
    margin: 0 1rem 0.5rem 1rem
    padding-top: 0.2rem

    &:hover
      color: vars.$platinum
      background-color: vars.$brandy
      text-decoration: none !important

@media only screen and (max-width: 1199px)
  #Basket
    padding-top: 8rem
    padding-bottom: 1rem
    min-height: unset

    a.OrderButton
      width: 16rem
      font-size: 17pt

@media only screen and (max-width: 768px)
  #Basket h4
    font-size: 17pt
