@use '../../../vars'

#WelcomeModal

  .modal-header, .modal-footer
    display: flex
    justify-content: center
    background-color: vars.$platinum

  .modal-body
    text-align: center
    flex-direction: column
    background-color: vars.$platinum

  .modal-footer
    padding-bottom: 0

  h4, p
    font-weight: bold

  p
    font-size: 15pt
    margin-bottom: 0

  button
    font-family: 'Sitka Display', serif !important
    font-weight: bold
    text-align: center
    background-color: vars.$jet
    color: vars.$grey
    font-size: 20pt
    width: 16rem
    border-radius: 10rem
    margin: 0 1rem 1.5rem 1rem
    padding-top: 0.4rem
    cursor: pointer
    outline: none
    border: 0
