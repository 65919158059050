@use '../../../../vars'

#PizzaSize
  @extend #Component

  #ExceptBasket
    @extend #Content

  .PageHead
    @extend #Head

    .BackButton
      @extend #BackButton

    .Header
      @extend #Header

  .Options a
    @extend #Option
    height: 52vh
    font-size: 50pt


@media only screen and (max-width: 1200px)
  #PizzaSize .Options a
    margin-bottom: 3rem

@media only screen and (max-width: 992px)
  #PizzaSize .Options a
    font-size: 40pt !important

@media only screen and (min-height: 600px)
  #PizzaSize .Options a
    height: 57vh
    font-size: 50pt

@media only screen and (min-height: 800px)
  #PizzaSize .Options a
    height: 56vh
    font-size: 70pt

@media only screen and (min-height: 950px)
  #PizzaSize .Options a
    height: 62vh
    font-size: 70pt

@media only screen and (min-height: 1080px)
  #PizzaSize .Options a
    height: 66vh

@media only screen and (min-height: 1200px)
  #PizzaSize .Options a
      height: 70vh

@media only screen and (min-height: 1400px)
  #PizzaSize .Options a
    height: 73.4vh

@media only screen and (min-height: 1600px)
  #PizzaSize .Options a
    height: 76vh

@media only screen and (min-height: 1800px)
  #PizzaSize .Options a
    height: 78vh

@media only screen and (min-height: 2000px)
  #PizzaSize .Options a
    height: 80vh
