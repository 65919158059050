@use 'vars'

body
  background-color: vars.$platinum

h1, h2, h3, h4, h5, h6
  font-family: vars.$heading-font

a, button, span, p
  font-family: vars.$default-font

#PageRow
  min-height: 100vh

#ExceptBasket
  margin-top: 6rem

